import { useState } from 'react'
import styled from 'styled-components'

import SearchInput from './Common/Components/SearchInput'
import {
  addToWatchList,
  getWatchList,
  removeFromWatchList,
  WatchListItem,
} from './Common/Helpers/watchList'

import Item from './Common/Components/Item'
import SelectModal from './Common/Components/SelectModal'
import PlayModal from './Common/Components/PlayModal'

const Body = () => {
  const [watchList, setWatchList] = useState(getWatchList())
  const [modalVisible, setModalVisible] = useState<{
    type: string
    item: Partial<WatchListItem>
  } | null>(null)

  return (
    <>
      <Container>
        <Row $centerHorizontally>
          <SearchInput
            onSelected={item => setModalVisible({ type: 'select', item })}
          />
        </Row>
        <Watchlist>
          {watchList.map(item => (
            <Item
              key={`watchList-${item.link}`}
              item={item}
              onClick={() => setModalVisible({ type: 'play', item })}
              onAction={action => {
                if (action === 'info') {
                  setModalVisible({ type: 'select', item })
                } else {
                  setWatchList(removeFromWatchList(item))
                }
              }}
            />
          ))}
        </Watchlist>
      </Container>
      {modalVisible?.type === 'select' && (
        <SelectModal
          item={modalVisible.item}
          onClickOutside={() => setModalVisible(null)}
          onSelected={item => {
            setWatchList(addToWatchList(item as WatchListItem))
            setModalVisible({ type: 'play', item })
          }}
        />
      )}
      {modalVisible?.type === 'play' && (
        <PlayModal
          item={modalVisible.item}
          onClickOutside={() => {
            setWatchList(getWatchList())
            setModalVisible(null)
          }}
        />
      )}
    </>
  )
}

export default Body

const Container = styled.div`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.primary};
  height: calc(100vh - 40px);
  width: calc(100vw - 40px);
  display: flex;
  flex-direction: column;
  padding: 20px;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Row = styled.div<{ $centerHorizontally?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${props =>
    props.$centerHorizontally ? 'center' : 'flex-start'};
  flex-wrap: wrap;
`

const Watchlist = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  gap: 20px;
`
