import { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { WatchListItem } from '../Helpers/watchList'
import Icon from './Icon'

interface Props extends HTMLAttributes<HTMLDivElement> {
  item: WatchListItem
  // eslint-disable-next-line no-unused-vars
  onAction: (action: 'info' | 'remove') => any
}

const Item = ({ item, onAction, ...props }: Props) => (
  <Container {...props}>
    <Row>
      <Icon
        large
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onAction('info')
        }}
      >
        info
      </Icon>
      <Icon
        large
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onAction('remove')
        }}
      >
        close
      </Icon>
    </Row>
    <Img src={item.img} alt={item.name} />
    <P>{item.name}</P>
    {!!item.episodeName && item.episodeName !== item.name && (
      <P>{item.episodeName}</P>
    )}
  </Container>
)

export default Item

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
  max-width: 150px;
  align-items: flex-start;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Img = styled.img`
  margin-bottom: 5px;
  max-width: inherit;
`

const P = styled.p`
  word-break: break-word;
`
