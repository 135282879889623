import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
  removeFromWatchList,
  updateWatchListItem,
  WatchListItem,
} from '../Helpers/watchList'
import s2dEpisode from '../Helpers/s2dEpisode'
import s2dFilm from '../Helpers/s2dFilm'

import ModalComponent, { Props as ModalProps } from './Modal'
import Icon from './Icon'

interface Props extends ModalProps {
  item: Partial<WatchListItem>
}

const SelectModal = ({ item, onClickOutside }: Props) => {
  const [currentItem, setCurrentItem] = useState(item)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined)
  const [displayCloseIcon, setDisplayCloseIcon] = useState(false)

  const handleNextEpisode = async () => {
    if (!currentItem.isFilm && currentItem.nextEpisodeLink) {
      const episode = await s2dEpisode(currentItem.nextEpisodeLink)
      if (episode) {
        setCurrentItem({
          ...currentItem,
          episode: episode.episode,
          episodeName: episode.episodeName,
          episodeLink: currentItem.nextEpisodeLink,
          nextEpisodeLink: episode.nextEpisodeLink,
        })
        if (videoRef?.current) {
          videoRef.current.currentTime = 0
        }
      }
    } else {
      onClickOutside()
      removeFromWatchList(currentItem as WatchListItem)
    }
  }

  const onMouseMove = () => {
    if (!displayCloseIcon) setDisplayCloseIcon(true)
    clearTimeout(timer as unknown as number)
    setTimer(setTimeout(() => setDisplayCloseIcon(false), 10000))
  }

  useEffect(() => {
    if (videoRef?.current && item.seek) {
      videoRef.current.currentTime = item.seek
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef])

  useEffect(() => {
    const cb = currentItem.isFilm ? s2dFilm : s2dEpisode
    cb(currentItem.episodeLink as string).then(d => {
      if (d) {
        setCurrentItem({
          ...item,
          episode: d.episode,
          nextEpisodeLink: d.nextEpisodeLink,
        })
      }
    })
    window.addEventListener('mousemove', onMouseMove)
    return () => window.removeEventListener('mouseMove', onMouseMove)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal onClickOutside={onClickOutside}>
      <Video
        ref={videoRef}
        src={currentItem.episode}
        autoPlay
        controls
        onProgress={({ target }) =>
          updateWatchListItem({
            ...currentItem,
            seek: (target as any).currentTime,
          } as WatchListItem)
        }
        onEnded={handleNextEpisode}
      />
      {displayCloseIcon && (
        <CloseIcon large onClick={onClickOutside}>
          close
        </CloseIcon>
      )}
    </Modal>
  )
}

export default SelectModal

const Modal = styled(ModalComponent)`
  width: 100vw;
  height: 100vh;
  background-color: black;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  max-height: 100vh;
  position: relative;
`

const Video = styled.video`
  max-width: inherit;
  max-height: inherit;
  outline: none;
`

const CloseIcon = styled(Icon)`
  color: ${props => props.theme.primary};
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`
