import { HTMLAttributes, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import s2dSearch, { SearchResult } from '../Helpers/s2dSearch'
import { WatchListItem } from '../Helpers/watchList'

import useBlur from '../Hooks/useBlur'

import Input from './Input'

interface Props extends HTMLAttributes<HTMLDivElement> {
  // eslint-disable-next-line no-unused-vars
  onSelected: (item: Partial<WatchListItem>) => any
}

const SearchInput = ({ onSelected, ...props }: Props) => {
  const [value, setValue] = useState('')
  const [results, setResults] = useState<SearchResult[]>([])
  const [showResults, setShowResults] = useState(false)
  const containerRef = useRef(null)

  useBlur(() => setShowResults(false), containerRef, [
    containerRef,
    setShowResults,
  ])

  useEffect(() => {
    if (value) {
      s2dSearch(value).then(d => setResults(d))
    }
  }, [value])

  return (
    <Container {...props} ref={containerRef}>
      <Input
        placeholder="Search..."
        icon="search"
        delay={500}
        value={value}
        onChange={({ target }) => setValue(target.value)}
        onFocus={() => setShowResults(true)}
      />
      {showResults && (
        <Results>
          {results.map(result => (
            <Result
              key={`searchInput-${result.link}`}
              onClick={() => {
                onSelected(result)
                setShowResults(false)
              }}
            >
              <Img src={result.img} alt={result.name} />
              <p>{result.name}</p>
              <p>({result.date})</p>
            </Result>
          ))}
        </Results>
      )}
    </Container>
  )
}

export default SearchInput

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Results = styled.div`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.background};
  border-radius: 5px;
  margin-top: 5px;
  overflow: auto;
  max-height: 300px;
`

const Result = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.secondary};
  }

  & > :not(:last-child) {
    margin-right: 5px;
  }
`

const Img = styled.img`
  max-height: 100px;
`
