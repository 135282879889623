import axios from 'axios'

export interface Season {
  season?: string
  episodes?: Array<{
    name: string
    link: string
  }>
  film?: string
}

const s2dEpisodes = async (link: string) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `https://s2d.maxime-farrugia.com/function/s2d/episodes`,
      params: {
        data: link,
      },
    })
    return res.data as Array<Season>
  } catch (err) {
    console.error(err)
    return []
  }
}

export default s2dEpisodes
