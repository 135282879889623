import { createPortal } from 'react-dom'
import styled from 'styled-components'

export interface Props {
  className?: string
  onClickOutside: () => any
  children?: React.ReactNode
}

const element = document.body.appendChild(document.createElement('div'))

const Modal = ({ className, onClickOutside, children }: Props) =>
  createPortal(
    <ModalContainer onClick={onClickOutside}>
      <ContentWrapper className={className} onClick={e => e.stopPropagation()}>
        {children}
      </ContentWrapper>
    </ModalContainer>,
    element,
  )

Modal.defaultProps = {
  className: '',
  children: null,
}

export default Modal

const ModalContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContentWrapper = styled.div`
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  background-color: #ffffff;
  border-radius: 5px;
  margin: 20px;

  @media (max-width: 576px) {
    & > div {
      padding: 20px;
    }
  }
`
