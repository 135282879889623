import { useEffect } from 'react'

const useBlur = (
  // eslint-disable-next-line no-unused-vars
  callback: (e: MouseEvent) => void,
  ref: React.RefObject<HTMLElement>,
  deps: React.DependencyList,
) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref?.current && !ref.current.contains(e.target as Node)) callback(e)
    }
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...deps])
}

export default useBlur
