const KEY = '@s2d/watchList'

export interface WatchListItem {
  img: string
  name: string
  link: string
  episode: string
  episodeName: string
  episodeLink: string
  seek: number
  nextEpisodeLink?: string
  isFilm?: boolean
}

export const getWatchList = (): WatchListItem[] => {
  const watchList = localStorage.getItem(KEY)
  return watchList ? JSON.parse(watchList) : []
}

export const addToWatchList = (item: WatchListItem): WatchListItem[] => {
  const watchList = getWatchList()
  const index = watchList.findIndex(i => i.name === item.name)

  if (index >= 0) return updateWatchListItem(item)
  localStorage.setItem(KEY, JSON.stringify([item, ...watchList]))
  return getWatchList()
}

export const updateWatchListItem = (item: WatchListItem): WatchListItem[] => {
  const watchList = getWatchList()
  const index = watchList.findIndex(i => i.name === item.name)

  if (index < 0) return addToWatchList(item)
  localStorage.setItem(
    KEY,
    JSON.stringify([
      item,
      ...watchList.slice(0, index),
      ...watchList.slice(index + 1),
    ]),
  )
  return getWatchList()
}

export const removeFromWatchList = (item: WatchListItem): WatchListItem[] => {
  const watchList = getWatchList()
  const index = watchList.findIndex(i => i.name === item.name)

  if (index < 0) return getWatchList()
  localStorage.setItem(
    KEY,
    JSON.stringify([
      ...watchList.slice(0, index),
      ...watchList.slice(index + 1),
    ]),
  )
  return getWatchList()
}
