import { useState, forwardRef, useEffect, InputHTMLAttributes } from 'react'
import styled from 'styled-components'

import Icon from './Icon'

type InputAttrs = InputHTMLAttributes<HTMLInputElement>

export interface Props extends InputAttrs {
  className?: string
  helperText?: string
  icon?: string
  onIconClick?: InputAttrs['onClick']
  delay?: number
  value?: InputAttrs['value']
  onChange?: InputAttrs['onChange']
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      helperText,
      icon,
      onIconClick,
      delay,
      value,
      onChange,
      ...props
    }: Props,
    ref,
  ) => {
    const [delayedValue, setDelayedValue] = useState<Props['value']>(
      value || '',
    )
    const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined)

    useEffect(() => {
      setDelayedValue(value)
    }, [value])

    return (
      <>
        <InputWrapper className={className}>
          <StyledInput
            {...props}
            ref={ref}
            value={delay ? delayedValue : value}
            autoComplete="off"
            onChange={e => {
              setDelayedValue(e.target.value)
              if (delay && delay > 0) {
                e.persist()
                clearTimeout(timer as unknown as number)
                setTimer(setTimeout(() => onChange?.(e), delay))
              } else {
                onChange?.(e)
              }
            }}
          />
          {!!icon && (
            <StyledIcon clickable={!!onIconClick} onClick={onIconClick}>
              {icon}
            </StyledIcon>
          )}
        </InputWrapper>
        {!!helperText && <HelperSpan>{helperText}</HelperSpan>}
      </>
    )
  },
)

Input.defaultProps = {
  className: '',
  helperText: undefined,
  icon: '',
  onIconClick: undefined,
  delay: 0,
  value: '',
  onChange: undefined,
}

export default Input

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.primary};
  color: ${props => props.theme.background};
  border: 1px solid ${props => props.theme.accent};
  border-radius: 5px;
`

const StyledInput = styled.input`
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  border-radius: inherit;
  padding: 0 10px;
  width: 100%;
  min-height: 40px;
  font-style: normal;
  font-weight: normal;

  &::placeholder {
    color: ${props => props.theme.background};
  }

  &:focus {
    outline: none;
  }
`

const StyledIcon = styled(Icon)<{ clickable: boolean }>`
  position: relative;
  padding-right: 10px;
  cursor: ${props => (props.clickable ? 'pointer' : 'normal')};
  font-size: 20px;
`

const HelperSpan = styled.span`
  margin-top: 5px;

  &,
  & > * {
    text-align: left;
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
  }

  & > * {
    margin-top: 0px;
  }
`
