import axios from 'axios'

export interface SearchResult {
  img: string
  date: string
  name: string
  link: string
}

const s2dSearch = async (title: string) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `https://s2d.maxime-farrugia.com/function/s2d/search`,
      params: {
        data: title,
      },
    })
    return res.data as Array<SearchResult>
  } catch (err) {
    console.error(err)
    return []
  }
}

export default s2dSearch
