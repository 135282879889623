import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

import IconComponent from './Icon'
import buttonStyle, { Props as ButtonStyleProps } from '../Style/button'

interface Props
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonStyleProps {
  icon?: string
}

const Button = ({ icon, children, ...props }: Props) => (
  <StyledButton {...props}>
    {!!icon && <Icon>{icon}</Icon>}
    {children}
  </StyledButton>
)

Button.defaultProps = {
  icon: '',
}

export default Button

const StyledButton = styled.button<Props>`
  ${buttonStyle}
`

const Icon = styled(IconComponent)`
  margin-right: 5px;
`
