import { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'

import Body from './Body'

const App = () => {
  const theme = useMemo(
    () => ({
      background: '#2e3440',
      primary: '#eceff4',
      secondary: '#80c0d0',
      accent: '#5e81ac',
    }),
    [],
  )

  return (
    <ThemeProvider theme={theme}>
      <Body />
    </ThemeProvider>
  )
}

export default App
