import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { WatchListItem } from '../Helpers/watchList'
import s2dEpisodes, { Season } from '../Helpers/s2dEpisodes'
import Icon from './Icon'

import ModalComponent, { Props as ModalProps } from './Modal'
import Select from './Select'
import Button from './Button'

interface Props extends ModalProps {
  item: Partial<WatchListItem>
  // eslint-disable-next-line no-unused-vars
  onSelected: (item: Partial<WatchListItem>) => any
}

const SelectModal = ({ item, onClickOutside, onSelected }: Props) => {
  const [seasons, setSeasons] = useState<Season[]>([])
  const [selectedSeason, setSelectedSeason] = useState<{
    label: string
    value: string
  } | null>(null)

  useEffect(() => {
    s2dEpisodes(item.link as string).then(d => {
      setSeasons(d)
      if (!d.some(s => s.film)) {
        setSelectedSeason({
          label: d[0].season as string,
          value: d[0].season as string,
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal onClickOutside={onClickOutside}>
      <Row>
        <Img src={item.img} alt={item.name} />
        <Column>
          <h3>{item.name}</h3>
          {!!selectedSeason && <p>{seasons.length} seasons</p>}
        </Column>
        <CloseIcon large onClick={onClickOutside}>
          close
        </CloseIcon>
      </Row>
      {!!seasons.length && !seasons.some(s => s.film) && (
        <Label htmlFor="season">
          Season
          <Select
            id="season"
            options={seasons.map(({ season }) => ({
              label: season as string,
              value: season as string,
            }))}
            value={selectedSeason}
            onChange={e => setSelectedSeason(e)}
          />
        </Label>
      )}
      {!!seasons.length && seasons.some(s => s.film) && (
        <Button
          type="button"
          onClick={() => {
            onSelected({
              ...item,
              episodeLink: item.link,
              isFilm: true,
            })
          }}
        >
          Play
        </Button>
      )}
      {!!selectedSeason && (
        <Episodes>
          {seasons
            .find(s => s.season === selectedSeason.value)
            ?.episodes?.map?.(ep => (
              <Episode key={ep.link}>
                <p>{ep.name}</p>
                <Button
                  onClick={() =>
                    onSelected({
                      ...item,
                      episodeLink: ep.link,
                      episodeName: ep.name,
                    })
                  }
                >
                  Play
                </Button>
              </Episode>
            ))}
        </Episodes>
      )}
    </Modal>
  )
}

export default SelectModal

const Modal = styled(ModalComponent)`
  padding: 20px;
  overflow: auto;
  max-height: calc(100vh - 40px);

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Img = styled.img`
  max-height: 200px;
  margin-right: 10px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const CloseIcon = styled(Icon)`
  margin-left: auto;
  cursor: pointer;
`

const Label = styled.label`
  font-weight: 500;
`

const Episodes = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  & > :not(:last-child) {
    margin-bottom: 5px;
  }
`

const Episode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > button {
    margin-left: 5px;
  }
`
