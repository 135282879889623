import { forwardRef, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  large?: boolean
}

const Icon = forwardRef<HTMLSpanElement, Props>(
  ({ large, ...props }: Props, ref) => <I {...props} large={large} ref={ref} />,
)

Icon.defaultProps = {
  large: false,
}

export default Icon

const I = styled.span<{ large?: boolean }>`
  font-family: 'Material Icons';
  ${props =>
    props.large &&
    css`
      font-size: 24px;
    `}
`
