import { useContext } from 'react'
import SelectComponent, {
  Props as SelectProps,
  components,
  OptionProps,
  SingleValueProps,
  MultiValueProps,
  MultiValueRemoveProps,
  GroupBase,
} from 'react-select'
import styled, { ThemeContext } from 'styled-components'

import Icon from './Icon'

const OptionComponent = <
  Option extends { label: string; value: any },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  data,
  isSelected,
  ...props
}: OptionProps<Option, IsMulti, Group>) => (
  <components.Option {...props} data={data} isSelected={isSelected}>
    {data.label}
    {isSelected && <CheckIcon>check</CheckIcon>}
  </components.Option>
)

const SingleValue = <
  Option extends { label: string; value: any },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  data,
  ...props
}: SingleValueProps<Option, IsMulti, Group>) => (
  <components.SingleValue {...props} data={data}>
    {data.label}
  </components.SingleValue>
)

const MultiValue = <
  Option extends { label: string; value: any },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  data,
  ...props
}: MultiValueProps<Option, IsMulti, Group>) => (
  <components.MultiValue {...props} data={data}>
    {data.label}
  </components.MultiValue>
)

const MultiValueRemove = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>,
) => (
  <components.MultiValueRemove {...props}>
    <CancelIcon>cancel</CancelIcon>
  </components.MultiValueRemove>
)

const Select = <
  Option extends { label: string; value: any },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group>,
) => {
  const defaultTheme = useContext(ThemeContext)

  return (
    <SelectComponent
      {...props}
      theme={selectTheme => ({
        ...selectTheme,
        colors: {
          ...selectTheme.colors,
          primary: defaultTheme.accent,
          primary25: defaultTheme.secondary,
          primary50: defaultTheme.secondary,
          neutral10: defaultTheme.accent,
        },
      })}
      components={{
        Option: OptionComponent,
        SingleValue,
        MultiValue,
        MultiValueRemove,
      }}
      styles={{
        control: base => ({
          ...base,
          borderRadius: '5px',
          minHeight: '42px',
          cursor: 'pointer',
          boxShadow: undefined,
          '&:hover': undefined,
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        indicatorsContainer: base => ({
          ...base,
          color: 'inherit',
        }),
        dropdownIndicator: base => ({
          ...base,
          color: 'inherit',
          ':hover': undefined,
        }),
        menu: base => ({
          ...base,
          borderRadius: '5px',
          marginTop: '10px',
          marginBottom: '10px',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
        }),
        menuList: base => ({
          ...base,
          paddingTop: '5px',
          paddingBottom: '5px',
        }),
        option: base => ({
          ...base,
          cursor: 'pointer',
        }),
        multiValue: base => ({
          ...base,
          borderRadius: '20px',
        }),
        multiValueRemove: base => ({
          ...base,
          ':hover': undefined,
        }),
        clearIndicator: base => ({
          ...base,
          color: 'inherit',
          ':hover': undefined,
        }),
      }}
    />
  )
}

export default Select

const CheckIcon = styled(Icon)`
  position: absolute;
  right: 0;
  padding-right: 10px;
`

const CancelIcon = styled(Icon)`
  font-size: 16px;
  color: #7a7a7a;
`
