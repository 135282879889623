import { css, DefaultTheme, ThemedStyledProps } from 'styled-components'

export interface Props {
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  $color?: 'normal' | 'invert'
  $invert?: boolean
}

const getFontSize = (props: ThemedStyledProps<Props, DefaultTheme>) => {
  if (props.size === 'small') return '12px'
  if (props.size === 'large') return '16px'
  return '14px'
}

const getBackgroundColor = (
  props: ThemedStyledProps<Props, DefaultTheme>,
  { notTransparent = false }: { hover: boolean; notTransparent?: boolean },
) => {
  if (props.$invert && !notTransparent) return 'transparent'
  if (props.$color === 'invert') return props.theme.primary
  return props.theme.background
}

const getColor = (props: ThemedStyledProps<Props, DefaultTheme>) => {
  if (props.$invert && props.$color === 'invert') return props.theme.background
  return props.theme.primary
}

const getBorderColor = (props: ThemedStyledProps<Props, DefaultTheme>) => {
  if (props.$color === 'invert') return props.theme.primary
  if (props.$invert) return props.theme.background
  return 'transparent'
}

export default css<Props>`
  position: relative;
  padding: 8px 15px;
  font-weight: 600;
  font-size: ${props => getFontSize(props)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${props => getBackgroundColor(props, { hover: false })};
  color: ${props => getColor(props)};
  border: 1px solid ${props => getBorderColor(props)};
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  display: flex;
  align-items: center;
`
