import axios from 'axios'

const s2dFilm = async (link: string) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `https://s2d.maxime-farrugia.com/function/s2d/film`,
      params: {
        data: link,
      },
    })
    return res.data as { episode: string; nextEpisodeLink?: string }
  } catch (err) {
    console.error(err)
    return null
  }
}

export default s2dFilm
